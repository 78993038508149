import React from 'react'
import { hooks } from '@front/volcanion'

import { TableAction } from '../..'

const RemoveRowAction = ({ name, getDialogExtra = _.noop, getDialogState = _.identity, ...props }) => {
  const [, , { removeSection }] = hooks.useSection(hooks.useSectionPath())
  const isFormReadOnly = hooks.useFormStatus('isReadOnly')
  if (!!isFormReadOnly)
    return null
  return (
    <TableAction
      {...props}
      onClick={removeSection}
    />
  )
}

export default React.memo(RemoveRowAction)

import React from 'react'
import styles from './styles'
import { FormControl, FormLabel, FormControlLabel, Radio, RadioGroup, FormHelperText, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
const useStyles = makeStyles(styles)
import { withSelector } from '@front/volcanion'
import Loader from '../../Loader'
const RadioComponent = (props) => {
  const {
    name,
    value = '',
    label,
    onChange,
    options,
    isLoading,
    valueType,
    helperText,
    error,
    inputRef,
    orientation = 'horizontal',
    selectorProps,
    onInputChange,
    displayEmpty,
    formLabelProps,
    disableTypography,
    disabled,
    ...custom
  } = props
  const classes = useStyles(props)
  const {
    getOptionLabel,
    getOptionValue,
    getOptionKey,
    getOptionLabelProps,
  } = selectorProps

  return (
    <FormControl ref={inputRef} className={classes.root} name={name} error={error} {...custom}>
      {!!label && <FormLabel id={`${name}-button-group`} {...formLabelProps}>{label}</FormLabel>}
      <Loader isLoading={isLoading}>
        <RadioGroup
          aria-labelledby={`${name}-button-group`}
          value={value}
          onChange={event => onChange(event.target.value)}
          row={orientation === 'horizontal'}
        >
          {
            _.map(options, (option) => <FormControlLabel
              disableTypography={disableTypography}
              labelPlacement={_.get(option, 'labelPlacement') || 'end'}
              componentsProps={{
                typography: {
                  variant: 'subtitle1'
                }
              }}
              key={getOptionKey(option)}
              disabled={disabled}
              {...getOptionLabelProps(option)}
              control={<Radio />}
              label={getOptionLabel(option)}
              value={getOptionValue(option)}
            />)
          }
        </RadioGroup>
      </Loader>
      <Box display={!!helperText ? 'block' : 'none'}>
        <FormHelperText>{helperText}</FormHelperText>
      </Box>
    </FormControl>
  )
}

export default React.memo(withSelector(RadioComponent, { loadOnFocus: false }))

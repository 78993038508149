import colors from './colors'

const light = (mode, primary) => ({
  type: mode,
  primary,
  secondary: {
    main: colors['secondary']
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.87)',
    disabled: 'rgba(0, 0, 0, 0.87)',
    placeholder: 'rgba(0, 0, 0, 0.87)'
  },
  background: {
    default: colors['gray-light'],
    paper: colors['gray-light'],
    popover: colors['white'],
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    seleted: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
  icon: {
    main: colors['gray-dark'],
  },
  white: colors['white'],
  extraWhite: colors['extraWhite'],
  orange: 'rgba(245, 124, 0, 1.3)',
  blue: colors['deep-blue'],
  red: colors['vermillion'],
  gray: colors['gray-light'],
  grayDark: colors['gray-dark'],
  moon: '#F4F1C9',
  sun: '#f9d71c'
})

const dark = (mode, primary) => ({
  type: mode,
  primary,
  secondary: {
    main: colors['secondary']
  },
  text: {
    primary: 'rgba(255, 255, 255, .7)',
    secondary: 'rgba(255, 255, 255, .7)',
    disabled: 'rgba(255, 255, 255, .7)',
    placeholder: colors['gray-light']
  },
  background: {
    default: colors['gray-dark'],
    paper: primary[mode],
    popover: colors['gray-dark'],
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: 'rgba(0, 0, 0, 0.04)',
    seleted: 'rgba(0, 0, 0, 0.08)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
  icon: {
    main: 'rgba(255, 255, 255, .7)'
  },
  white: 'rgba(255, 255, 255, .7)',
  orange: 'rgba(245, 124, 0, 1.3)',
  blue: colors['deep-blue'],
  red: colors['vermillion'],
  gray: colors['gray-light'],
  grayDark: colors['gray-dark'],
  moon: '#F4F1C9',
  sun: '#f9d71c'
})

const theme = (mode = 'light', primary) => mode === 'light' ? light(mode, primary) : dark(mode, primary)

export default theme

import React from 'react'
import { InputLabel, FormControl, Select, FormHelperText, Box } from '@mui/material'

import ValueDecorator from '../../decorators/Value'
import MenuDecorator from '../../decorators/Menu'
import { withSelector } from '@front/volcanion'

import { Loader } from '../'

const MenuSelector = (props) => {
  const {
    name,
    value = '',
    options = [],
    displayEmpty,
    multiple,
    onChange,
    label,
    valueType = !!multiple ? 'chip' : 'standard',
    menuType = !!multiple ? 'checkbox' : 'standard',
    valueDecoratorProps,
    menuDecoratorProps,
    isLoading,
    helperText,
    error,
    inputRef,
    selectorProps,
    disabled,
    meta,
    onInputChange,
    disableUnderline = false,
    // DEPRECATED
    labelProps,
    selectProps,
    isReady,
    sx: _sx = {},
    _loader = {},
    _label = {},
    ...custom
  } = props
  const { getOptionValue, getOptionKey } = selectorProps

  const sx = _.merge({}, !!disabled && {
    '.MuiSvgIcon-root': {
      display: 'none'
    }
  }, _sx)

  return (
    <Loader isLoading={isReady === false} size={20} {..._loader}>
      <FormControl
        name={name}
        sx={{ width: 1 }}
        error={error}
        {...custom}
      >
        {!!label && <InputLabel disabled={disabled} id={`${name}-select-label`} {...labelProps} {..._label}>{label}</InputLabel>}
        <Select
          displayEmpty={displayEmpty}
          inputProps={{ ref: inputRef }}
          labelId={`${name}-select-label`}
          id={`${name}-select`}
          multiple={multiple}
          value={value}
          disabled={disabled}
          disableUnderline={!!disabled || !!disableUnderline}
          onChange={(e) => onChange(e.target.value)}
          renderValue={selected => <ValueDecorator
            {...valueDecoratorProps}
            selectorProps={selectorProps}
            valueType={valueType}
            value={selected}
          />}
          sx={sx}
          {...selectProps}
        >
          {_.map(options, (option, key) => {
            return <MenuDecorator
              {...menuDecoratorProps}
              key={getOptionKey(option) || key}
              value={getOptionValue(option)}
              selectorProps={selectorProps}
              option={option}
              menuType={menuType}
            />
          })
          }

        </Select>
        <Box display={!!helperText ? 'block' : 'none'}>
          <FormHelperText>{helperText}</FormHelperText>
        </Box>
      </FormControl >
    </Loader>
  );
}

export default React.memo(withSelector(MenuSelector, { resetOnChange: true, loadOnFocus: false }))

import React, { useMemo } from 'react'
import { Grid, Stepper } from '@mui/material';
import _ from 'lodash'
import ViewSelector from '@front/volcanion/components/ViewSelector'

const getGroupsCount = (groupsCount, childrenCount, { autoFill, autoFillLast }) => {
  if (!!autoFill) {
    const countDiff = childrenCount - _.sum(groupsCount)
    if (countDiff <= 0)
      return groupsCount
    if (!!autoFillLast) {
      const last_count = _.takeRight(groupsCount) || 0
      return _.flatten([_.dropRight(groupsCount, 1), last_count + countDiff])
    }
    return _.flatten([groupsCount, countDiff])
  }
  return groupsCount
}
const getChunkedChildren = (children, groupsCount) => {
  return _.map(groupsCount, (groupCount, groupIndex) => {
    const current_index = _.sum(_.slice(groupsCount, 0, groupIndex))
    return _.slice(_.flatten([children]), current_index, current_index + groupCount);
  })
}
const getStepIndex = (groupsCount, group_index, step_index) => {
  return _.sum(_.slice(groupsCount, 0, group_index)) + step_index
}
const GridStepper = ({
  children,
  groupsCount: inputGroups = [],
  getGridProps = _.noop,
  getStepperProps = _.noop,
  getStepProps = _.noop,
  autoFill = true,
  autoFillLast = false,
  ...props
}) => {
  const groupsCount = useMemo(
    () => getGroupsCount(inputGroups, _.flatten([children]).length, { autoFill, autoFillLast }),
    [inputGroups.join(','), children.length, autoFill, autoFillLast]
  )
  const chunked_children = useMemo(() => getChunkedChildren(children, groupsCount), [children, groupsCount.join(',')])
  const colCount = chunked_children.length
  return (
    <Grid container>
      {
        _.map(chunked_children, (chunked_group, group_index) => (
          <Grid key={group_index} item xs={12 / colCount} {...getGridProps(group_index)} >
            <Stepper {...props}  {...getStepperProps(group_index)}  >
              {
                _.map(chunked_group, (step, step_index) => {
                  const global_index = getStepIndex(groupsCount, group_index, step_index)
                  return (
                    <ViewSelector
                      key={step_index}
                      index={global_index}
                      expanded
                      last={global_index === children.length - 1}
                      active={true}
                      {...getStepProps(step_index, group_index)}
                    >
                      {step}
                    </ViewSelector>
                  );
                })
              }
            </Stepper>
          </Grid>
        ))
      }
    </Grid>
  );
}

export default React.memo(GridStepper)
